import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`One of the issues of opsdroid was to bump the test coverage of the parsers.  Aiohttp is used to connect to the different API's  and a `}<inlineCode parentName="p">{`ClientOSError`}</inlineCode>{` exception is raised, when the client fails to connect to the parser API.`}</p>
    <p>{`I've decided to take on the task to find a way to test these two lines of code that everyone seems to be having issues with.`}</p>
    <p>{`After doing a bunch of reading and trying different ways to raise the exception, I've finally come to this idea that perhaps mocking a failed connection could work.`}</p>
    <p>{`From the unit test documentation, the `}<inlineCode parentName="p">{`side_effect`}</inlineCode>{` method looked like something that could provide the answer.`}</p>
    <p><em parentName="p">{`side_effect allows you to perform side effects, including raising an exception when a mock is called -- `}<a parentName="em" {...{
          "href": "https://docs.python.org/3/library/unittest.mock.html"
        }}>{`Unit Test Documentation`}</a></em></p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`async def test_parse_witai_raise_ClientOSError(self):
        with OpsDroid() as opsdroid:
            opsdroid.config['parsers'] = [
                {'name': 'witai', 'access-token': 'test', 'min-score': 0.3}
            ]
            mock_skill = amock.CoroutineMock()
            match_witai('get_weather')(mock_skill)

            mock_connector = amock.CoroutineMock()
            message = Message("how's the weather outside", "user",
                              "default", mock_connector)

            with amock.patch.object(witai, 'call_witai') as mocked_call:
                mocked_call.side_effect = ClientOSError()
                await witai.parse_witai(opsdroid, message,
                                        opsdroid.config['parsers'][0])

            self.assertFalse(mock_skill.called)
            self.assertTrue(mocked_call.called)
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`call_witai`}</inlineCode>{` is the only part of the parser that connects to the Wit.AI API, so this bit is mocked. Then we need to try and parse a message so we use `}<inlineCode parentName="p">{`await witai.parse_witai(opsdroid, message,opsdroid.config['parsers'][0])`}</inlineCode></p>
    <p>{`Since the mocked call to Wit.AI API is returning the exception `}<inlineCode parentName="p">{`ClientOSError()`}</inlineCode>{` the skill mock_skill - `}<inlineCode parentName="p">{`mock_skill = amock.CoroutineMock()`}</inlineCode>{` is not called. But, the mocked call to the API is called so both the tests pass.`}</p>
    <p>{`The test passes and the coverage were bumped up, but it feels that the exception wasn't really tested at all. All my attempts to assert the exception with `}<inlineCode parentName="p">{`self.assertRaises()`}</inlineCode>{` failed so this is what I came up with.`}</p>
    <p>{`Do you have any other way that this exception could be tested? I would love to hear from you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      